import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import {View, Image, ImageSourcePropType} from 'react-native';
import Text from '@basicComponents/text';
import i18n from '@i18n';
import {toPriceStr} from '@utils';
import globalStore from '@/services/global.state';
import Button from '@basicComponents/button';

import theme from '@style';
import {vipOptionsMap} from '@businessComponents/vip';
const {flex, padding, background, font, fontSize} = theme;
interface MeVipProps {
  level: number;
  nextLevelValue: number;
  renderProgress: any;
  onPress: () => void;
}

const MeVip: React.FC<MeVipProps> = ({
  level,
  nextLevelValue,
  onPress,
  renderProgress,
}) => {
  return (
    <NativeTouchableOpacity>
      <View
        style={[
          background.white,
          flex.flex,
          flex.row,
          padding.lrl,
          flex.centerByCol,
          flex.between,
          {height: 49, borderRadius: 49},
        ]}>
        <Image
          source={vipOptionsMap[level].small as ImageSourcePropType}
          style={[
            {
              height: (globalStore.screenWidth * 34) / 375,
              width: (globalStore.screenWidth * 40) / 375,
            },
          ]}
        />

        <View style={[flex.flex1, padding.lrl, flex.centerByRow, {height: 49}]}>
          <View style={[theme.flex.row]}>{renderProgress}</View>
          <View style={[flex.row, theme.margin.topxxs]}>
            <Text
              numberOfLines={2}
              style={[font.main, {opacity: 0.5}]}
              main
              fontSize={fontSize.xs}>
              {i18n.t('me.vip.recharge')}{' '}
              <Text blod>{toPriceStr(nextLevelValue, {fixed: 0})}</Text>{' '}
              {i18n.t('me.vip.move')} VIP{level + 1}
            </Text>
          </View>
        </View>
        <Button size="xsmall" titleBold title={'Deposit'} onPress={onPress} />
      </View>
    </NativeTouchableOpacity>
  );
};

export default MeVip;
