import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {goTo} from '@/utils';
import React, {forwardRef} from 'react';
import {View, ViewProps, Image} from 'react-native';
import {useTranslation} from 'react-i18next';

const HomeColor = forwardRef<View, ViewProps>((props, ref) => {
  const {style, ...otherProp} = props;
  const colorWidth = globalStore.screenWidth - 4 * theme.paddingSize.l;

  const {i18n} = useTranslation();

  return (
    <View
      ref={ref}
      style={[
        {
          minHeight: (colorWidth / 351) * 142,
        },
        // theme.borderRadius.s,
        // theme.margin.btml,
        // theme.background.white,
        // theme.border.mainShallow,
        style,
      ]}
      {...otherProp}>
      <View
        style={[
          theme.flex.row,
          theme.padding.tbs,
          theme.padding.lrl,
          theme.flex.centerByCol,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            height: 52,
          },
        ]}>
        <Image
          source={require('@/assets/icons/home/game-name-img.webp')}
          style={{
            width: theme.iconSize.m,
            height: theme.iconSize.m,
            marginRight: theme.paddingSize.s,
          }}
        />
        <Text
          size="large"
          main
          fontFamily="fontInter"
          style={{fontWeight: '600'}}>
          {i18n.t('home.color.title')}
        </Text>
      </View>
      <View style={[theme.padding.lrl, theme.padding.btml]}>
        <NativeTouchableOpacity
          style={[theme.overflow.hidden, theme.borderRadius.s]}
          onPress={() => {
            goTo('GameWebView', {
              type: 'color',
            });
            // postMessage('router:/pages/colorPrediction/colorPrediction');
          }}>
          <LazyImage
            width={colorWidth}
            height={122}
            imageUrl={require('@assets/icons/home/color.webp')}
            resizeMode={'stretch'}
          />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
});

export default HomeColor;
