import {
  defaultHeaderImg,
  emptyHeaderImg,
  headerSize,
  rightIcon,
  rightIconSize,
  toLogin,
} from './me.variable';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import {View} from 'react-native';
import LazyImage from '@basicComponents/image';
import Text from '@basicComponents/text';
import {IUserInfo} from '@services/global.service';
import {vipOptionsMap} from '@businessComponents/vip';
import i18n from '@i18n';

const {flex, padding, font, margin, fill} = theme;
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {goTo} from '@/utils';

interface MeUserProps {
  login?: boolean;
  user?: IUserInfo;
  level: number;
  onUser?: () => void;
  showNoMenu?: boolean;
}

const MeUser: React.FC<MeUserProps> = ({
  login,
  user,
  level,
  onUser,
  showNoMenu,
}) => {
  return (
    <NativeTouchableOpacity
      onPress={() => (login ? goTo('Profile') : onUser && onUser())}>
      <View style={[flex.row, margin.btmm, flex.between, flex.centerByCol]}>
        <View style={[flex.row, flex.center, flex.between, fill.fillW]}>
          <View style={[flex.row]}>
            <View style={[margin.rightl]}>
              <LazyImage
                occupancy={'transparent'}
                radius={50}
                resizeMode="cover"
                imageUrl={
                  globalStore.token
                    ? user?.userAvatar
                      ? user.userAvatar
                      : defaultHeaderImg
                    : emptyHeaderImg
                }
                width={(globalStore.screenWidth * headerSize) / 375}
                height={(globalStore.screenWidth * headerSize) / 375}
              />
            </View>
            {login ? (
              <View style={[flex.col, flex.centerByRow]}>
                <View style={[flex.row]}>
                  <Text blod style={[font.white, font.fm, margin.rights]}>
                    {user?.userName ? user?.userName : user?.userPhone}
                  </Text>
                  <LazyImage
                    occupancy="#0000"
                    imageUrl={vipOptionsMap[level].sign}
                    width={(globalStore.screenWidth * 45) / 375}
                    height={(globalStore.screenWidth * 15) / 375}
                  />
                </View>
                <View style={[flex.row, margin.tops, flex.centerByCol]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={require('@assets/icons/me/mobile.webp')}
                    width={10}
                    height={14}
                  />
                  <Text
                    style={[margin.leftxxs, margin.rightxxl, font.white]}
                    fontSize={theme.fontSize.s}>
                    {user?.userPhone}
                  </Text>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={require('@assets/icons/me/userId.webp')}
                    width={14}
                    height={14}
                  />
                  <Text
                    style={[margin.leftxxs, font.white]}
                    fontSize={theme.fontSize.s}>
                    {+(user?.userId || 0) + 100000}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={[flex.col, flex.centerByRow, flex.alignStart]}>
                <Text style={[font.fs, font.white, padding.btmxxs]}>
                  {i18n.t('me.user.moreExciting')}
                </Text>
                {/* TODO 这里会引起报错,button不能是另一个botton的子元素 */}
                <Button
                  title={i18n.t('me.user.loginUpper')}
                  type="linear-primary"
                  titleBold
                  size="small"
                  onPress={toLogin}
                />
              </View>
            )}
          </View>

          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={(rightIconSize / 3) * 2}
            height={rightIconSize}
          />
        </View>
        {showNoMenu && (
          <LazyImage
            occupancy={'transparent'}
            imageUrl={rightIcon}
            width={rightIconSize}
            height={rightIconSize}
          />
        )}
      </View>
    </NativeTouchableOpacity>
  );
};

export default MeUser;
