import Text from '@basicComponents/text';
import React, {useState} from 'react';
import {View, Image} from 'react-native'; //ImageBackground
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import {goTo, toPriceStr} from '@/utils';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {combineLatest, distinctUntilChanged} from 'rxjs';
import {MessageCountInfo, postUserInfo} from '@services/global.service';
import {useTranslation} from 'react-i18next';
import {useFocusEffect} from '@react-navigation/native';
const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

const HomeHeader = (props: {
  setMenuOpen: (bool: boolean, unreadCount?: number) => void;
  version: number;
}) => {
  const {i18n} = useTranslation();
  const {setMenuOpen, version} = props;
  const [showLogin, setShowLogin] = React.useState(false);
  const [showUser, setShowUser] = React.useState(false);
  const [userName, setUserName] = React.useState(false);
  const [userAvatar, setUserAvatar] = React.useState('');
  const [amount, setAmount] = React.useState<number>(0);
  const [rate, setRate] = React.useState<number>(0);
  // const styles = StyleSheet.create({
  //   bellTipIcon: {
  //     position: 'absolute',
  //     top: 4,
  //     right: 4,
  //   },
  // });
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  useFocusEffect(
    React.useCallback(() => {
      const sub = combineLatest([
        globalStore.tokenSubject,
        globalStore.globalLoading,
      ]).subscribe(([t, l]) => {
        // 没有token且没有加载时,显示login按钮
        setShowLogin(!t && !l);
        setShowUser(!!t);
        if (t) {
          postUserInfo().then(res => {
            globalStore.userInfo = res;
            setUserName(res.userName || res.userPhone);
            setUserAvatar(res.userAvatar);
          });
        }
      });
      const amountSub = globalStore.amountChanged.subscribe(res => {
        if (res.current) {
          setAmount(res.current);
        }
      });
      const rateSub = globalStore.rateSubject
        .pipe(distinctUntilChanged())
        .subscribe(v => {
          setRate(v);
        });

      const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
        setMessageCountInfo(
          _countInfo || {
            messageTotalCount: 0,
            sysMessageCount: 0,
            sysUserMessageCount: 0,
          },
        );
      });
      return () => {
        sub.unsubscribe();
        amountSub.unsubscribe();
        rateSub.unsubscribe();
        msgSub.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]),
  );

  return (
    <DetailNavTitle
      rate={rate}
      showProgress
      containerStyle={[
        theme.flex.row,
        theme.flex.centerByCol,
        theme.background.transparent,
        {
          paddingTop: theme.paddingSize.zorro,
          paddingBottom: theme.paddingSize.zorro,
        },
      ]}
      hideServer
      leftNode={
        <View style={[theme.flex.centerByCol, theme.flex.row]}>
          <NativeTouchableOpacity>
            <Image
              style={[
                {
                  width: 52,
                  height: 42,
                },
              ]}
              source={require('@assets/icons/home-logo.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      }
      hideAmount
      rightNode={
        <View style={[theme.flex.centerByCol, theme.flex.row]}>
          {showUser && (
            <NativeTouchableOpacity
              onPress={() => goTo('Me')}
              style={[theme.flex.centerByCol, theme.flex.row]}>
              <Image
                source={userAvatar ? {uri: userAvatar} : defaultHeaderImg}
                style={[theme.icon.xxl, {borderRadius: theme.iconSize.xxl / 2}]}
              />
              <View style={[theme.flex.col, theme.margin.lefts]}>
                <Text
                  accent
                  textAlign="left"
                  color={theme.fontColor.white}
                  style={[
                    {
                      marginBottom: -theme.paddingSize.xxs / 2,
                    },
                  ]}>
                  {userName}
                </Text>
                <Text
                  color={theme.fontColor.white}
                  textAlign="left"
                  size="medium"
                  blod>
                  {toPriceStr(amount)}
                </Text>
              </View>
            </NativeTouchableOpacity>
          )}
          {showUser && true && (
            <NativeTouchableOpacity
              onPress={() => {
                goTo('Notification');
              }}
              style={[
                theme.padding.s,
                theme.position.rel,
                {
                  marginRight: theme.paddingSize.s,
                },
              ]}>
              <Image
                style={[theme.icon.xl]}
                source={require('@assets/icons/bell.webp')}
                resizeMode={'cover'}
              />
              {/* {messageCountInfo?.messageTotalCount > 0 && (
                <ImageBackground
                  style={[theme.icon.m, styles.bellTipIcon]}
                  source={require('@components/assets/icons/tip-dot.webp')}
                />
              )} */}
            </NativeTouchableOpacity>
          )}

          {/* 暂时隐藏侧边栏开关 */}
          {false && (
            <NativeTouchableOpacity
              style={[theme.flex.row, theme.flex.centerByCol]}
              onPress={() =>
                setMenuOpen(true, messageCountInfo?.messageTotalCount)
              }>
              <Image
                style={[theme.icon.m, theme.margin.rightxxs]}
                source={require('@assets/icons/menu.webp')}
              />
            </NativeTouchableOpacity>
          )}
          {showLogin && (
            <NativeTouchableOpacity
              onPress={() => {
                goTo('Login');
              }}>
              <View
                style={[
                  theme.flex.center,
                  theme.padding.lrxs,
                  {
                    width: 63,
                    height: 25,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#00bb8f',
                    borderRadius: 13,
                  },
                ]}>
                <Text
                  fontFamily="fontInterBold"
                  color={theme.fontColor.primary}>
                  {i18n.t('me.user.login')}
                </Text>
              </View>
            </NativeTouchableOpacity>
          )}
          {showLogin && (
            <NativeTouchableOpacity
              onPress={() => {
                goTo('SingUp');
              }}>
              <View
                style={[
                  theme.flex.center,
                  theme.padding.lrxs,
                  {
                    width: 63,
                    height: 25,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#00bb8f',
                    borderRadius: 13,
                    marginLeft: 10,
                  },
                ]}>
                <Text
                  fontFamily="fontInterBold"
                  color={theme.fontColor.primary}>
                  {i18n.t('me.user.register')}
                </Text>
              </View>
            </NativeTouchableOpacity>
          )}
        </View>
      }>
      <View style={[theme.flex.flex1]} />
    </DetailNavTitle>
  );
};

export default HomeHeader;
