import React from 'react';
import {MatkaListItem} from '../../home.type';
import Text from '@basicComponents/text';
import {View, StyleSheet} from 'react-native';
import theme from '@/style';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo} from '@/utils'; //toPriceStr
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';

function getResultNum(
  openResultNum?: string | null,
  closeResultNum?: string | null,
) {
  let openNum, closeNum;
  if (openResultNum) {
    openNum = openResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    openNum = '*';
  }
  if (closeResultNum) {
    closeNum = closeResultNum.split('').reduce((s, y) => (s + +y) % 10, 0);
  } else {
    closeNum = '*';
  }
  return {openNum, closeNum};
}

const HomeMatkaCard = ({
  item,
  marginRight,
  cardWidth,
  imageHeight,
}: {
  item: MatkaListItem;
  marginRight?: boolean;
  cardWidth: number;
  imageHeight: number;
}) => {
  const {i18n} = useTranslation();
  const {openNum, closeNum} = getResultNum(
    item.openResultNum,
    item.closeResultNum,
  );

  const handlePress = () => {
    if (item.isClose === 2) {
      globalStore.globalTotal.next({
        type: 'warning',
        message: i18n.t('home.tip.closed', {
          name: item.lotteryName,
        }),
      });
      return;
    }
    goTo('GameWebView', {
      type: 'matka',
      params: `data=${JSON.stringify(item)}`,
    });
  };
  return (
    <NativeTouchableOpacity
      style={[
        {
          width: cardWidth,
        },
        marginRight ? theme.margin.rights : null,
        theme.flex.col,
        theme.borderRadius.s,
        theme.overflow.hidden,
        theme.background.white,
      ]}
      onPress={handlePress}>
      <LazyImageBackground
        height={imageHeight}
        width={cardWidth}
        imageUrl={item.backImg}
        style={[
          theme.flex.col,
          theme.flex.end,
          theme.padding.lrs,
          theme.padding.tbl,
        ]}>
        <View
          style={[
            theme.flex.flex,
            theme.flex.center,
            {
              backgroundColor: '#db5b48',
              opacity: 0.88,
              width: cardWidth / 2.4,
              height: cardWidth / 7.8,
              borderTopLeftRadius: cardWidth / 3.15,
              borderBottomLeftRadius: cardWidth / 3.15,
              position: 'absolute',
              top: cardWidth / 12,
              right: 0,
            },
          ]}>
          <Text
            fontSize={theme.fontSize.xs}
            blod
            fontWeight="900"
            style={[theme.font.white]}>
            {item.openResultNum || '***'}-{openNum}
            {closeNum}-{item.closeResultNum || '***'}
          </Text>
        </View>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
            theme.padding.lrs,
            {
              width: cardWidth,
              height: cardWidth / 4.5,
              backgroundColor: 'rgba(3,3,3,0.40)',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          ]}>
          <View
            style={[theme.flex.col, theme.flex.alignStart, theme.margin.lefts]}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text fontSize={theme.fontSize.xs} white style={[{opacity: 0.5}]}>
                {i18n.t('home.matka.open')}
              </Text>
            </View>
            <Text fontSize={theme.fontSize.xs} white>
              {item.openDraw}
            </Text>
          </View>
          <View
            style={[theme.flex.col, theme.flex.alignEnd, theme.margin.rights]}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text fontSize={theme.fontSize.xs} white style={[{opacity: 0.5}]}>
                {i18n.t('home.matka.close')}
              </Text>
            </View>
            <Text fontSize={theme.fontSize.xs} white>
              {item.closeDraw}
            </Text>
          </View>
        </View>

        {/* <View style={[theme.flex.center]}>
          <View
            style={[
              theme.padding.tbxs,
              theme.padding.lrl,
              theme.margin.btmxxs,
              styles.matkaArea,
            ]}>
            <Text
              fontSize={theme.fontSize.xs}
              blod
              fontWeight="900"
              style={[theme.font.white]}>
              {item.openResultNum || '***'}-{openNum}
              {closeNum}-{item.closeResultNum || '***'}
            </Text>
          </View>
        </View> */}
        {/* <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.padding.lrs,
          ]}>
          <Text
            fontFamily="fontDin"
            fontSize={theme.fontSize.s}
            blod
            style={[theme.font.white]}>
            {toPriceStr(item.minPrice, {
              currency: globalStore.currency,
              fixed: 0,
            })}
            /{i18n.t('home.matka.bid')}
          </Text>
          <Text
            fontFamily="fontDin"
            fontSize={theme.fontSize.s}
            blod
            style={[theme.font.white]}>
            -
          </Text>
          <Text
            fontFamily="fontDin"
            fontSize={theme.fontSize.s}
            blod
            style={[theme.font.white]}>
            {i18n.t('home.matka.win') + ' '}
            {toPriceStr(item.wonAmount, {
              currency: globalStore.currency,
              fixed: 0,
            })}
          </Text>
        </View> */}
        {/* <View
          style={[
            theme.fill.fillW,
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
          ]}>
          <View
            style={[theme.flex.col, theme.flex.alignStart, theme.margin.lefts]}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text fontSize={theme.fontSize.xs} white>
                {i18n.t('home.matka.open')}
              </Text>
            </View>
            <Text fontSize={theme.fontSize.xs} white>
              {item.openDraw}
            </Text>
          </View>
          <View
            style={[theme.flex.col, theme.flex.alignEnd, theme.margin.rights]}>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text fontSize={theme.fontSize.xs} white>
                {i18n.t('home.matka.close')}
              </Text>
            </View>
            <Text fontSize={theme.fontSize.xs} white>
              {item.closeDraw}
            </Text>
          </View>
        </View> */}
      </LazyImageBackground>
      {item.isClose === 2 && (
        <View
          style={[
            theme.background.white,
            theme.fill.fill,
            theme.position.abs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              opacity: 0.5,
              left: 0,
              bottom: 0,
            },
          ]}>
          <View style={[styles.closed]}>
            <LazyImage
              imageUrl={require('@assets/icons/home/closed.webp')}
              width={theme.imageSize.m}
              height={theme.imageSize.m}
              occupancy="#0000"
            />
          </View>
        </View>
      )}
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  matkaArea: {
    borderRadius: 32,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.20)',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
  closed: {
    zIndex: 5,
    position: 'absolute',
    right: 2,
    top: 8,
  },
});

export default HomeMatkaCard;
