import React from 'react';
import Text from '@basicComponents/text';
import Home from './pages/home';
import Me from './pages/me';
import theme from '@style';
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {ImageURISource, Image, View} from 'react-native'; //ImageBackground
// import Casino from './common-pages/casino';
import {NavigatorScreenProps} from '@types';
// import LiveCasino from './common-pages/live-casino';
import Result from './common-pages/result';
import {Shadow} from 'react-native-shadow-2';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import globalStore from './services/global.state';
import {goTo} from './utils';
import Promotion from './common-pages/promotion';
import ProxyHome from './common-pages/proxy';
// import Sports from './common-pages/sports';

const Tab = createBottomTabNavigator();

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img: ImageURISource;
  activeImg: ImageURISource;
  unmountOnBlur?: boolean;
  hide?: boolean;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: require('@assets/icons/home/home-inselect.webp'),
    activeImg: require('@assets/icons/home/home-select.webp'),
  },
  // {
  //   name: 'Live',
  //   link: 'index/live',
  //   component: LiveCasino,
  //   img: require('@assets/icons/home/activity-inselect.webp'),
  //   activeImg: require('@assets/icons/home/activity-select.webp'),
  // },
  {
    name: 'Promotion',
    link: 'index/promotion',
    component: Promotion,
    img: require('@assets/icons/home/activity-inselect.webp'),
    activeImg: require('@assets/icons/home/activity-select.webp'),
  },
  {
    name: 'ProxyHome',
    link: 'index/ProxyHome',
    component: ProxyHome,
    img: require('@assets/icons/home/agency-center.webp'),
    activeImg: require('@assets/icons/home/agency-center.webp'),
  },
  {
    name: 'Result',
    link: 'index/result',
    component: Result,
    img: require('@assets/icons/home/results-inselect.webp'),
    activeImg: require('@assets/icons/home/results-select.webp'),
  },
  // {
  //   name: 'Sports',
  //   link: 'index/sports',
  //   component: Sports,
  //   img: require('@assets/icons/home/sports.webp'),
  //   activeImg: require('@assets/icons/home/sports-active.webp'),
  // },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: require('@assets/icons/home/account-inselect.webp'),
    activeImg: require('@assets/icons/home/account-select.webp'),
  },
];

const CusTab = (props: BottomTabBarProps) => {
  return (
    <Shadow
      {...theme.shadow.defaultShadow}
      style={[
        theme.flex.row,
        theme.flex.around,
        theme.background.white,
        theme.fill.fillW,
        theme.shadow.defaultShadow.style,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 56,
        },
      ]}>
      {/* <ImageBackground
        source={require('@assets/icons/home/tabbarBackImage.webp')}
        resizeMode={'cover'}
        style={[
          theme.flex.row,
          theme.fill.fillW,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 56},
        ]}>
        {props.state?.routes.map((route, index) => {
          const {options} = props.descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? (options.tabBarLabel as string)
              : options.title !== undefined
              ? options.title
              : route.name;
          const isFocused = props.state.index === index;
          const onPress = () => {
            if (!isFocused) {
              if (options.title === 'Sports' && !globalStore.token) {
                goTo('Login');
              } else {
                goTo(route.name);
              }
            }
          };
          return (
            <NativeTouchableOpacity
              key={label}
              activeOpacity={0.8}
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              style={[theme.flex.center, theme.flex.flex1, theme.padding.tbs]}>
              <Image
                style={theme.icon.l}
                source={
                  isFocused
                    ? mainPageList[index].activeImg
                    : mainPageList[index].img
                }
              />
              <Text
                blod={true}
                fontSize={10}
                style={{
                  color: isFocused
                    ? theme.fontColor.main
                    : theme.fontColor.main,
                }}>
                {label}
              </Text>
            </NativeTouchableOpacity>
          );
        })}
      </ImageBackground> */}
      <View
        style={[
          theme.flex.row,
          theme.fill.fillW,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 56},
        ]}>
        {props.state?.routes.map((route, index) => {
          const {options} = props.descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? (options.tabBarLabel as string)
              : options.title !== undefined
              ? options.title
              : route.name;
          const isFocused = props.state.index === index;
          const onPress = () => {
            if (!isFocused) {
              if (options.title === 'Agency' && !globalStore.token) {
                goTo('Login');
              } else {
                goTo(route.name);
              }
            }
          };
          return (
            // <NativeTouchableOpacity
            //   key={label}
            //   activeOpacity={0.8}
            //   accessibilityRole="button"
            //   accessibilityState={isFocused ? {selected: true} : {}}
            //   accessibilityLabel={options.tabBarAccessibilityLabel}
            //   testID={options.tabBarTestID}
            //   onPress={onPress}
            //   style={[theme.flex.center, theme.flex.flex1, theme.padding.tbs]}>
            //   <Image
            //     style={theme.icon.l}
            //     source={
            //       isFocused
            //         ? mainPageList[index].activeImg
            //         : mainPageList[index].img
            //     }
            //   />
            //   <Text
            //     blod={true}
            //     fontSize={10}
            //     style={{
            //       color: isFocused
            //         ? theme.fontColor.main
            //         : theme.fontColor.main,
            //     }}>
            //     {label}
            //   </Text>
            // </NativeTouchableOpacity>
            <NativeTouchableOpacity
              key={label}
              activeOpacity={0.8}
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              style={[
                theme.flex.center,
                theme.flex.flex1,
                {overflow: 'visible'},
                // eslint-disable-next-line react-native/no-inline-styles
              ]}>
              <Image
                // style={[
                //   isFocused
                //     ? // eslint-disable-next-line react-native/no-inline-styles
                //       {
                //         marginTop: -14,
                //         width: 42,
                //         height: 42,
                //       }
                //     : // eslint-disable-next-line react-native/no-inline-styles
                //       {height: 28, width: 28},
                // ]}
                style={[
                  theme.icon.l,
                  index === 2
                    ? {
                        marginTop: -25,
                        width: 47,
                        height: 47,
                      }
                    : {},
                ]}
                source={
                  isFocused
                    ? mainPageList[index].activeImg
                    : mainPageList[index].img
                }
              />
              <Text
                blod={true}
                fontSize={10}
                style={{
                  color: theme.fontColor.main,
                }}>
                {label}
              </Text>
            </NativeTouchableOpacity>
          );
        })}
      </View>
    </Shadow>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();
  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => <CusTab {...props} />}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
