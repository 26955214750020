import {
  View,
  // StyleSheet,
  Platform,
  LayoutChangeEvent,
  // BackHandler,
  RefreshControl,
  Animated,
  ImageBackground,
} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useCallback, useRef, useState} from 'react';
import theme from '@style';
import {goTo, navigate, toAgentApply, goCS} from '@utils'; //designToDp
import Text from '@basicComponents/text';
import {VipProgress} from '@businessComponents/vip';
import {
  MeListItem,
  agencyIcon,
  commissionIcon,
  customerServiceIcon,
  languagesIcon,
  moneyIcon,
  notificationsIcon,
  passwordIcon,
  rebateIcon,
  resultHistoryIcon,
  transactionsIcon,
  updateIcon,
  // gameIcon,
} from '@businessComponents/list-item';
// import Tag from '@basicComponents/tag';
import {postVipInfo} from './me.service';
import {
  IUserInfo,
  MessageCountInfo,
  postUserInfo,
  postVipConfig,
} from '@services/global.service';
import {useConfirm, ToastType} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useFocusEffect} from '@react-navigation/native';
import globalStore from '@/services/global.state';
import {toLogin} from './me.variable';
import MeHeader from './me-header';
import MeAmount from './me-amount';
import MeUser from './me-user';
import MeVip from './me-vip';
import {FadeInView} from '@basicComponents/animations';
import {NoMoreData} from '@/components/basic/default-page';
import {useVersionModal} from '@/common-pages/hooks/versionmodal.hooks';
import {getVersion} from 'react-native-device-info';
import Spin from '@/components/basic/spin';
import {useTranslation} from 'react-i18next';
import MeRowMenu from './me-row-menu';
import {getNoticeCheck} from '../home/home.service';
import Tag from '@/components/basic/tag';
import LinearGradient from '@/components/basic/linear-gradient';
import {BasicObject} from '@/types';

const {overflow, padding, paddingSize, font, margin, borderRadius, background} =
  theme;

// const topClipHeight = globalStore.isWeb ? 50 : 44;

interface VipInfo {
  level: number;
  diff: number; // 差多少值结束
  nextValue: number; // 该等级结束值
}

/** TODO 单个文件过大,需要拆解 */
const Me = () => {
  const {i18n} = useTranslation();
  const systemWidth = globalStore.screenWidth;
  const [login, setLogin] = useState<boolean>(false);
  const [vip, setVip] = useState<VipInfo>({
    level: 0,
    diff: 200,
    nextValue: 200,
  });
  const scrollAnim = useRef(new Animated.Value(0)).current;
  const topBlockWidth = systemWidth - 4 * paddingSize.m;
  const [user, setUser] = useState<IUserInfo>();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const {versionModal, handleUpdate} = useVersionModal(false);
  const [showNoMenu] = useState<boolean>(false);
  const [messageCountInfo, setMessageCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });

  const [userAreaY, setUserAreaY] = useState<number>(0);
  const firstFocus = useRef(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });

  const notYetWarning = {
    type: ToastType.warning,
    message: i18n.t('warning.unopen'),
  };
  const refresh = useCallback(
    async (token: string | null, showloading = true) => {
      showloading && setPageLoading(true);
      try {
        if (!token) {
          setLogin(false);
          postVipConfig().then(conf => {
            const currentConf = conf.find(c => c.level === 1);
            setVip({
              level: 0,
              diff: currentConf?.recharge || 200,
              nextValue: currentConf?.recharge || 200,
            });
          });
        } else {
          setLogin(true);
          const results = await Promise.allSettled([
            postUserInfo(),
            postVipInfo(),
          ]);
          const [userInfo, vipInfo] = results;
          if (userInfo.status === 'fulfilled') {
            setUser(userInfo.value);
            globalStore.userInfo = userInfo.value;
          }
          if (vipInfo.status === 'fulfilled') {
            const {value: list} = vipInfo;
            for (let i = 0; i < list.length; i++) {
              if (list[i].diff > 0) {
                const current = list[i];
                setVip({
                  level: current.level,
                  diff: current.diff,
                  nextValue: current.amount,
                });
                break;
              }
            }
          }
        }
      } finally {
        setPageLoading(false);
        setRefreshing(false);
      }
    },
    [],
  );
  const [rebateNum, setRebateNum] = React.useState(0);
  const getRebates = () => {
    if (globalStore.token) {
      getNoticeCheck()
        .then(noticeList => {
          const data = noticeList.find(v => v.noticeKey === 'REBATE');
          if (data) {
            setRebateNum(data.noticeCount);
          } else {
            setRebateNum(0);
          }
        })
        .catch(() => {
          setRebateNum(0);
        });
    }
  };

  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };

  const onFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
      refresh(token, firstFocus.current);
      firstFocus.current = false;
      doNotice(token);
      getRebates();
      if (token) {
        globalStore.amountCheckOut.next();
        globalStore.refreshNotification.next();
      } else {
        setMessageCountInfo({
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        });
      }
    });
    const msgSub = globalStore.notificationSubject.subscribe(_countInfo => {
      setMessageCountInfo(
        _countInfo || {
          messageTotalCount: 0,
          sysMessageCount: 0,
          sysUserMessageCount: 0,
        },
      );
    });
    return () => {
      sub.unsubscribe();
      msgSub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  useFocusEffect(onFocusEffect);

  const handleMoneyLayout = (e: LayoutChangeEvent) => {
    setUserAreaY(e.nativeEvent.layout.y);
  };

  const handleRefresh = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    refresh(globalStore.token);
  };

  const toVip = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Vip');
  };

  const toRecharge = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转withdraw
    goTo('Withdraw');
  };

  const toInvitation = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Invitation');
  };

  const toResults = () => {
    goTo('Result');
  };

  const toCommission = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    goTo('Invitation');
  };
  const toRebate = () => {
    if (!login) {
      toLogin();
      return;
    }
    // 跳转commission
    goTo('Rebate');
  };

  const toTransactions = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Transactions');
  };

  const toMyBets = () => {
    if (!login) {
      toLogin();
      return;
    }
    goTo('Bets');
  };

  const toNotify = () => {
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  // const toCasino = () => {
  //   goTo('Casino');
  // };

  const toLanguage = () => {
    languageShow();
  };

  const toUpdate = () => {
    // 更新
    handleUpdate();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      globalStore.token = null;
      globalStore.userInfo = null;
      toLogin();
    });
  };

  const toSetPassword = () => {
    if (!login) {
      toLogin();
      return;
    }
    navigate('SetPassword');
  };

  const toAgency = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (user?.isAgent === 1) {
      goTo('ProxyHome');
    } else {
      toAgentApply();
    }
  };

  const handleUser = () => {
    if (!login) {
      toLogin();
      return;
    }
    if (showNoMenu) {
      globalStore.globalTotal.next(notYetWarning);
    }
  };
  const [refreshing, setRefreshing] = useState<boolean>(false);
  return (
    <FadeInView>
      {/* TODO 这里的滚动方案需要优化,以及文件过大需要拆分 */}
      <Spin
        loading={pageLoading}
        style={[theme.fill.fill, theme.background.lightGrey]}>
        <Animated.ScrollView
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {y: scrollAnim},
                },
              },
            ],
            {useNativeDriver: true},
          )}
          scrollEventThrottle={1}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                refresh(globalStore.token, false);
                doNotice(globalStore.token);
              }}
            />
          }>
          <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 0, y: 1}}
            locations={[0, 0.8, 0.8, 1]}
            colors={theme.basicColor.primaryGrayLinearGradient}
            style={[background.lightGrey, {height: 299}]}>
            {/* <ImageBackground
              source={require('@assets/icons/header-backimg.webp')}
              style={[theme.padding.m, theme.margin.lrl, {height: 85}]}>
              <MeUser
                login={login}
                user={user}
                level={vip.level}
                onUser={handleUser}
                showNoMenu={showNoMenu}
              />
            </ImageBackground> */}
            <View style={[theme.padding.m, theme.margin.lrl, {height: 85}]}>
              <MeUser
                login={login}
                user={user}
                level={vip.level}
                onUser={handleUser}
                showNoMenu={showNoMenu}
              />
            </View>
            <ImageBackground
              source={require('@assets/icons/me/card-backimg.webp')}
              resizeMode="stretch"
              style={[
                theme.padding.lrxxl,
                theme.padding.topl,
                theme.flex.flex1,
                theme.flex.between,
                theme.background.transparent,
                {height: 214},
              ]}>
              <MeAmount
                login={login}
                onLayout={handleMoneyLayout}
                topBlockWidth={topBlockWidth}
                user={user}
                onRecharge={toRecharge}
                onWithdraw={toWithdraw}
                onRefresh={handleRefresh}
              />
              <MeVip
                level={vip.level}
                onPress={toVip}
                nextLevelValue={vip.nextValue}
                renderProgress={
                  <VipProgress
                    currentLevel={vip.level}
                    nextCurrentLevel={vip.level + 1}
                    current={vip.nextValue - vip.diff}
                    total={vip.nextValue}
                    hasCurrentText={true}
                  />
                }
              />
            </ImageBackground>
          </LinearGradient>

          <MeRowMenu
            onRecharge={toRecharge}
            onInvitation={toInvitation}
            onWithdraw={toWithdraw}
            noticeMap={noticeMap}
            onNotice={() => doNotice(globalStore.token)}
          />
          <View style={[padding.lrl, padding.btmxxl]}>
            {/* 列表区域 */}
            <View
              style={[
                background.white,
                padding.lrm,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              <MeListItem
                icon={agencyIcon}
                title={i18n.t('me.bottom.proxy')}
                // rightContent={
                //   <View
                //     style={[theme.flex.center, theme.padding.lrxs, styles.new]}>
                //     <Text fontSize={8} style={[theme.font.white]}>
                //       {i18n.t('me.new')}
                //     </Text>
                //   </View>
                // }
                onPress={toAgency}
              />
              <MeListItem
                icon={resultHistoryIcon}
                title={i18n.t('me.bottom.resultHistory')}
                onPress={toResults}
              />

              <MeListItem
                icon={moneyIcon}
                title={i18n.t('me.bottom.myBets')}
                onPress={toMyBets}
              />

              <MeListItem
                icon={transactionsIcon}
                title={i18n.t('me.bottom.myTransactions')}
                onPress={toTransactions}
              />
              <MeListItem
                icon={commissionIcon}
                title={i18n.t('me.bottom.commission')}
                onPress={toCommission}
              />
              <MeListItem
                icon={rebateIcon}
                title={i18n.t('home.label.rebate')}
                onPress={toRebate}
                rightContent={rebateNum ? <Tag content={rebateNum} /> : null}
              />
              {/* <MeListItem
                icon={gameIcon}
                title={i18n.t('home.tab.casino')}
                onPress={toCasino}
                hideBottomBorder
              /> */}
            </View>
            <View
              style={[
                background.white,
                padding.lrm,
                borderRadius.m,
                overflow.hidden,
                margin.btml,
              ]}>
              <MeListItem
                icon={notificationsIcon}
                title={i18n.t('me.bottom.notify')}
                rightContent={
                  messageCountInfo && messageCountInfo.messageTotalCount ? (
                    <Tag
                      badgeSize={16}
                      content={messageCountInfo.messageTotalCount}
                    />
                  ) : null
                }
                onPress={toNotify}
              />

              <MeListItem
                icon={passwordIcon}
                title={i18n.t('me.bottom.password')}
                onPress={toSetPassword}
              />

              <MeListItem
                icon={languagesIcon}
                title={i18n.t('me.bottom.lang')}
                onPress={toLanguage}
              />
              <MeListItem
                icon={customerServiceIcon}
                title={i18n.t('me.bottom.customer')}
                onPress={goCS}
                hideBottomBorder={Platform.OS === 'android' ? false : true}
              />
              {Platform.OS === 'android' && (
                <MeListItem
                  icon={updateIcon}
                  title={i18n.t('me.bottom.update')}
                  rightContent={
                    <Text style={[font.secAccent, font.fs]}>
                      {getVersion()} {globalStore.channel}
                    </Text>
                  }
                  onPress={toUpdate}
                />
              )}
            </View>
            {login && (
              <NativeTouchableOpacity onPress={doLogout}>
                <View
                  style={[
                    background.primary,
                    padding.lrm,
                    padding.tbl,
                    borderRadius.m,
                    overflow.hidden,
                    margin.btmxxl,
                  ]}>
                  <Text style={[font.white, font.fm, font.bold, font.center]}>
                    {i18n.t('me.bottom.logout')}
                  </Text>
                </View>
              </NativeTouchableOpacity>
            )}
            <NoMoreData text="" />
          </View>
        </Animated.ScrollView>
        <MeHeader
          user={user}
          userAreaY={userAreaY}
          login={login}
          scrollAnim={scrollAnim}
          onUser={handleUser}
          showNoMenu={showNoMenu}
        />
      </Spin>
      {renderConfirmModal}
      {renderLanguageModal}
      {versionModal.renderModal}
    </FadeInView>
  );
};

// const styles = StyleSheet.create({
//   topBg: {
//     /** 此处由于裁剪高度是根据宽度按比例换算的，所以这边也需要按照比例换算paddingTop */
//     paddingTop: designToDp(topClipHeight) + paddingSize.m,
//   },
//   opacity: {
//     opacity: 0.5,
//   },
//   lightOpacity: {
//     opacity: 0.8,
//   },
//   bottomNav: {
//     height: designToDp(80),
//   },
//   bottomNavItem: {
//     width: designToDp(104),
//   },
//   new: {
//     backgroundColor: '#FF4D4D',
//     borderRadius: 20,
//     paddingVertical: 2,
//   },
// });

export default Me;
