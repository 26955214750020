import theme from '@/style';
import React from 'react';
import {View, Animated} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
const gameTabs = ['Dice', 'Color', '3 Digits', 'Kerala', 'Matka'];

const Tabs = ({
  activeTab = 0,
  scrollToCurrentGame = () => {},
}: {
  scrollToCurrentGame: (index: number) => void;
  activeTab: number;
}) => {
  const tabAnim = React.useRef(new Animated.Value(0)).current;
  // const [itemWidth, setItemWidth] = React.useState(0);

  // const outputRange = React.useMemo(() => {
  //   return gameTabs.map((_, i) => i * itemWidth);
  // }, [itemWidth]);

  React.useEffect(() => {
    Animated.timing(tabAnim, {
      toValue: activeTab,
      duration: 120,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{height: 48}}>
      <View style={[theme.fill.fill]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.flex1,
            theme.padding.topxs,
            theme.flex.centerByCol,
            theme.background.white,
          ]}>
          {/* <Image
            style={[theme.icon.l, theme.margin.rights]}
            source={require('@assets/imgs/home/ball.webp')}
          /> */}
          <View
            style={[theme.flex.row, theme.flex.flex1, theme.flex.around]}
            // onLayout={e => {
            //   setItemWidth(e.nativeEvent.layout.width / gameTabs.length);
            // }}
          >
            {gameTabs.map((_, i) => (
              <NativeTouchableOpacity
                onPress={() => scrollToCurrentGame(i)}
                key={i}
                style={[
                  theme.flex.center,
                  activeTab === i
                    ? {
                        ...theme.background.primary,
                        ...theme.padding.lrl,
                        ...theme.padding.tbxxs,
                        ...theme.borderRadius.xl,
                      }
                    : {},
                ]}>
                <Text
                  size="medium"
                  blod={activeTab === i}
                  white={activeTab === i}>
                  {_}
                </Text>
              </NativeTouchableOpacity>
            ))}
          </View>
        </View>
      </View>
      {/* <Animated.View
        style={[
          {width: itemWidth},
          styles.lineContainer,
          {
            transform: [
              {
                translateX: tabAnim.interpolate({
                  inputRange: gameTabs.map((i, index) => index),
                  outputRange: outputRange,
                }),
              },
            ],
          },
        ]}>
        <View style={[styles.line]} />
      </Animated.View> */}
    </View>
  );
};

// const styles = StyleSheet.create({
//   lineContainer: {
//     position: 'absolute',
//     bottom: 6,
//     left: 44,
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   line: {
//     ...theme.background.primary,
//     height: 4,
//     width: 20,
//   },
// });

export default Tabs;
